@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #000300;
}

.custom-img {
  /* background-image: url(https://images.unsplash.com/photo-1461884430365-0a87b6123549?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80); */
  /* background-image: url(https://images.unsplash.com/photo-1552652893-2aa10a0ab4df?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80); */
  background-size: cover;
  background-size: no-repeat;
  opacity: 50%;
}

.hidden-hero {
  opacity: 0;
  transition: all 5s;
}

.show-hero {
 opacity: 1;
}

